
@use '~@/assets/sass/design.sass' as *;

.card {
  margin-bottom: 0;
}

.filters {
  margin-bottom: $base-spacing;
}

.images {
  &List {
    display: grid;
    grid-gap: $base-spacing;
  }

  &Container {
    position: relative;
  }
}

.options {
  &Overlay {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    display: flex;
    width: 100%;
    height: 100%;
    overflow: hidden;
    opacity: 0;
    background: $black-transparent-gradient;
    transition: opacity .3s ease-out;
  }

  &Tools {
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    min-height: 40%;
    background: $black-transparent-gradient;
  }
}

.image {
  position: relative;

  &Container {
    position: relative;
    margin-bottom: $base-spacing;
    cursor: pointer;
    box-shadow: $base-shadow;
    background: transparentize($c-acc-green, 0.8);
    background-size: $s-s $s-s;
    background-image:
      linear-gradient(to right, $c-affair-purple 1px, transparent 1px),
      linear-gradient(to bottom, $c-affair-purple 1px, transparent 1px);
    border-radius: 6px;
    overflow: hidden;

    &:hover {
      .optionsOverlay {
        border-radius: 6px;
        opacity: 1;
      }
    }

    &.selected {
      opacity: 0.1;
    }
  }
}

.resolution {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  padding-bottom: $s-xxs;
  z-index: $z-highlight;
  text-align: center;
  color: transparentize($c-white, 0.5);
}

.title {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  padding: $s-xxs $s-s;
  z-index: $z-highlight;
  text-align: center;
  color: transparentize($c-white, 0.5);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.paginationContainer {
  margin: 0 $s-s;
}

.icon {
  &Edit {
    color: $c-white;

    &:hover {
      color: $c-acc-blue-dark;
    }
  }

  &Delete {
    color: $c-white;
    cursor: pointer;

    &:hover {
      color: $c-acc-red;
    }
  }
}

.search {
  margin-bottom: $base-spacing / 4;
}
