
// Transitions
$prim-trans-time: .3s
$prim-trans-easing: ease-in-out

// Links
$link-decoration: none

// Elements
$base-shadow: 0 4px 10px -4px #000
$base-border-radius: 4px

// Typo
$font-family-heading: 'Exo', sans-serif
$font-family-paragraph: 'Noticia Text', serif
